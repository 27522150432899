<template>
  <!-- As a heading -->
  <nav class="navbar fixed-top navbar-light bg-light">
    <h2 class="navbar-brand text-light mb-0">Door Security</h2>
    <img
      src="/images/logo.png"
      alt="Denay Security System"
      style="height: 50px; width: auto"
    />
  </nav>
</template>
<script>
export default {
  name: 'Appname',
};
</script>
