<template>
  <div>
    <Appname></Appname>
    <!-- Begin page content -->
    <div class="container-fluid">
      <!-- Home page -->
      <div id="page-home" class="active">
        <div class="block">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Appname from '../components/TEMPLATE/Appname.vue';
import Footer from '../components/TEMPLATE/BottomNavbar.vue';
export default {
  name: 'Main',
  components: {
    Appname,
    Footer,
  },
};
</script>
