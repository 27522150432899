<template>
  <!-- Bottom Nav Bar -->
  <footer class="footer">
    <div
      id="buttonGroup"
      class="btn-group selectors justify-content-around bg-light"
      role="group"
    >
      <button id="home" class="button-inactive" @click="homePage">
        <div class="selector-holder">
          <i class="material-icons">home</i>
          <small>Home</small>
        </div>
      </button>
      <button id="settings" class="btn button-inactive" @click="settingPage">
        <div class="selector-holder">
          <i class="material-icons">settings</i>
          <small>Settings</small>
        </div>
      </button>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'BottomNavbar',
  methods: {
    homePage() {
      this.$router.push({ path: '/' });
    },
    settingPage() {
      this.$router.push({ path: '/setting' });
    },
  },
};
</script>
